<template>
  <div class="integrate_page">
    <div
      class="integrate_container" v-show="!showPlanChangeSection && !tabProperties.clientFuturePlan && !tabProperties.isSubscriptionCancelled">
      <div class="integrate_block" v-show="!tabProperties.clientPlan">
        <TitleText :titleText="integratePageTitle" />
        <div class="integrate_block_info">
          <div class="integrate_left_block">
            <IntegratePageItem v-for="item in integrateBlockItem" :key="item.id" :integrateBlockItem="item" />
          </div>
          <div class="integrate_right_block">
            <img src="../../assets/images/integratePage/integrateImg.png" />
          </div>
        </div>
      </div>
      <div class="plugin_api" v-show="!tabProperties.clientPlan">
        <IntegratePagePlugin :context="plugin" />
        <IntegratePagePlugin :context="api" />
      </div>
      <div v-if="tabProperties.clientFuturePlan || tabProperties.isSubscriptionCancelled"
        class="has-text-centered has-text-grey-dark has-text-weight-bold is-size-6">
        Your plan downgrade/cancel request already in-progress. No further changes allowed until next billing period.
      </div>
      <div class="cards_block" >
        <div class="wide_card">
          <span>{{ free.name }}</span>
          <div class="per_day">
            <div>
              <p>{{ free.description }}</p>

              <span>credits *</span>
            </div>
          </div>
          <div class="one_credit">
            <span>1 Credit = 1 'Execute' API call</span>
          </div>
          <div class="per_mounth">
            <div>
              <span>{{ free.price }}</span>
              <p>per month</p>
            </div>
          </div>
          <div class="wilde_card_button">
            <MyButton @openCommentBox="choose(free)">Subscribe</MyButton>
          </div>
        </div>
        <div class="integrate_card_block">

          <div v-for="plan in plans" v-bind:key="plan.price" class="integrate_card">
            <span>{{ plan.name }}</span>
            <div class="per_day">
              <div>
                <p>{{ plan.description }}</p>
                <span>credits*</span>
              </div>
            </div>
            <div class="one_credit">
              <span>1 Credit = 1 'Execute' API call</span>
            </div>
            <div class="per_mounth">
              <span>${{ plan.price }}</span>
              <p>per month</p>
            </div>
            <MyButton v-show="tabProperties.clientPlan!==plan.name" @openCommentBox="choose(plan)">Subscribe</MyButton>
            <div v-show="tabProperties.clientPlan===plan.name" class="has-text-danger">Your Current Plan</div>
          </div>
        </div>
        <div class="wide_card">
          <span>{{ custom.name }}</span>
          <div class="per_day">
            <div>
              <p>{{ custom.description }}</p>
              <span>credits *</span>
            </div>
          </div>
          <div class="one_credit">
            <span>1 Credit = 1 'Execute' API call</span>
          </div>
          <div class="per_mounth">
            <p class="start1">Start from</p>
            <div>
              <p class="start2">Start from</p>
              <span>{{ custom.price }}</span>
              <p>per month</p>
            </div>
          </div>
          <div class="wilde_card_button">
            <a class="my_button" href="https://app.monstercampaigns.com/c/eelbkirqhtobo1lclzwx/" target="_blank" rel="noopener noreferrer">Contact Us!</a>
          </div>
        </div>
        <p>*Credits per day</p>
      </div>
    </div>

    <div v-if="showPlanChangeSection && !tabProperties.clientFuturePlan" class="pay_block">
      <Spinner v-show="showInProgressSection" message="Updating your plan details. Please wait..." />
      <div v-show="!showInProgressSection">
        <div class="has-text-centered has-text-weight-bold is-size-6 has-text-grey-dark is-underlined"> Please Confirm
          the Plan
        </div>
        <div class="has-text-centered">
          <div class="select is-rounded plan-dropdown">
            <select v-model="selectedPlan" v-on:change="onApiPlanChange">
              <option v-for="plan in filteredPlans" v-bind:key="plan.name" v-bind:value="plan">{{ plan.name }}</option>
            </select>
          </div>
          <div class="api-subscription-sub-detail api-credits-when-subscribe">{{ selectedPlan.description }} Credits Per
            Day
          </div>
          <div class="api-subscription-sub-detail api-credits-when-subscribe">(*1 Credit = 1 Execute API Call)</div>
        </div>

        <div v-show="showCCInSubscription && selectedPlan.name !== 'Free'"
          class="has-text-centered has-text-weight-bold is-size-6 has-text-grey-dark is-underlined payment-section">
          Payment Information
        </div>
        <div v-show="showCCInSubscription && selectedPlan.name !== 'Free'" class="has-text-centered">
          <div>
            <div class="api-payment-box has-text-centered is-centered">
              <div id="apiUpdatePaymentMethod-card-element" class="payment-field has-text-centered">
              </div>
              <img :src="poweredByStripeImage" alt="Powered By Stripe" class="powered-by-stripe" />
            </div>
          </div>
        </div>
        <DoodleMessage v-show="doodleForm.errorMessage" :message="doodleForm.errorMessage" class="has-text-grey-dark"
          message-class="has-text-danger" />
        <div v-if="tabProperties.clientPlan">
          <div v-show="!showUpgradeNotes" class="notes">
            <span class="is-underlined">Note:</span> You are moving from higher volume plan to lower volume plan
            (downgrade).
            <ul>
              <li>As you may have already paid for your current plan for this billing cycle, Your current Plan will be
                active until end of your current billing Period.
              </li>
              <li>Your new plan will be active from start of Next Billing cycle.</li>
              <li>No further changes allowed in plans until next billing period</li>
              <li>Read <a href="/compiler-api/docs" target="_blank">API FAQ</a> for more details</li>
            </ul>
          </div>
          <div v-show="showUpgradeNotes" class="notes">
            <span class="is-underlined">Note:</span> You are moving from lower volume plan to upper volume plan
            (upgrade).
            <ul>
              <li>Your new Plan will be active immediately</li>
              <li v-show="tabProperties.clientPlan != 'Free'">Cost for current billing cycle will be calculated on prorate
                basis for your old and new plans
              </li>
              <li v-show="tabProperties.clientPlan != 'Free'">As you may have already paid for the current billing cycle
                for your current plan, difference in prorate calculation will be added in your next payment
              </li>
              <li>Read <a href="/compiler-api/docs" target="_blank">API FAQ</a> for more details</li>
            </ul>
          </div>
        </div>
        <div class="notes has-text-centered">By subscribing, you agree to the <a href="/terms" target="_blank">Terms of
            Service</a></div>
        <div class="update-button-section buttons is-centered">
          <button class="button is-danger has-text-weight-bold" @click="subscribePlan">Subscribe</button>
          <button class="button has-text-weight-bold" @click="closeSubscriptionForm">Close</button>
        </div>
      </div>
    </div>

    <Plugin v-show="!showPlanChangeSection && !tabProperties.clientFuturePlan && !tabProperties.isSubscriptionCancelled && !tabProperties.clientPlan"/>
    <OrganisationBlock />
    <DownloadBlock />

  </div>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'
import formMixin from '../../assets/javascript/form-mixin'
import stripeMixin from '../../assets/javascript/stripe-mixin'
import moment from 'moment'
import Spinner from '../Utils/Spinner'
import DoodleMessage from '../Utils/DoodleMessage'
import JdoodleForYou from '../Utils/JDoodleForYou'
import { GURU_EVENTS } from '../../assets/javascript/constants'
import MyButton from '../MyButton'
import TitleText from '../TitleText'
import IntegratePageItem from '../IntegratePage/components/IntegratePageItem'
import IntegratePagePlugin from '../IntegratePage/components/IntegratePagePlugin'
import OrganisationBlock from '../Utils/OrganisationBlock'
import DownloadBlock from '../Utils/DownloadBlock'
import Plugin from '../../views/Plugin.vue'

export default {
  name: 'apiPriceTable',
  components: {
    JdoodleForYou,
    DoodleMessage,
    Spinner,
    MyButton,
    TitleText,
    IntegratePageItem,
    IntegratePagePlugin,
    OrganisationBlock,
    DownloadBlock,
    Plugin
  },
  props: ['tabProperties'],
  mixins: [formMixin, stripeMixin],
  data: function () {
    return {
      plans: [
        { name: '$10', description: '1k', price: 10, style: '' },
        { name: '$20', description: '2k', price: 20, style: '' },
        { name: '$50', description: '5k', price: 50, style: '' },
        { name: '$100', description: '10k', price: 100, style: '' },
        { name: '$200', description: '20k', price: 200, style: '' },
        { name: '$300', description: '30k', price: 300, style: '' },
        { name: '$400', description: '40k', price: 400, style: '' },
        { name: '$500', description: '50k', price: 500, style: '' }
        /* { name: '$1000', description: '100,000', price: 1000, style: 'is-primary' } */
      ],
      filteredPlans: [],
      selectedPlan: null,
      showUpgradeNotes: false,
      showCCInSubscription: false,
      showPlanChangeSection: false,
      integratePageTitle: {
        title: 'Integrate',
        text: 'Integrate Compiler & IDE to your Blogs, Web App and Mobile Apps',
        width: '100%'
      },
      integrateBlockItem: [
        {
          id: 1,
          title: 'Do you have a technical blog?',
          text: 'Add an IDE and improve your user engagement.'
        },
        {
          id: 2,
          title:
            'Do you have an online programming course? \nAre your students jumping between your course and IDE?',
          text:
            'Make your course interactive by embedding an IDE directly into your course and provide a positive learning experience.'
        },
        {
          id: 3,
          title:
            'Do you need to validate a school assignment or assess a candidate in an online interview?',
          text: 'Send their program to our API to get it validated in a snap.'
        },
        {
          id: 4,
          title: 'Do you have a software library to demonstrate?',
          text:
            'Showcase the power of your great product using JDoodle API and Plugins.'
        }
      ],
      plugin: {
        title: 'Plugin',
        text1: 'Easy integration with just three lines of HTML code',
        text2: 'Free with JDoodle branding',
        text3: 'Tailor-made plugins to meet your requirements'
      },
      api: {
        title: 'Apis',
        text1: 'Standards-based REST and WebSocket APIs',
        text2:
          'Simplified request (your program) and response (output) solution',
        text3: 'Full control over your UI'
      },
      free: {
        name: 'Free',
        description: '200',
        price: '$0',
        style: ''
      },
      custom: {
        name: 'Custom',
        description: 'Unlimited ',
        price: '$990',
        style: ''
      }
    }
  },
  computed: {
    poweredByStripeImage() {
      return require('../../assets/images/powered_by_stripe.png')
    }
  },
  methods: {
    choose(plan) {
      if (this.$store.state.isUserLoggedIn === false) {
        eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Login'))
        return
      }
      window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
      if (plan.name === this.tabProperties.clientPlan) {
        return
      }

      if (this.tabProperties.clientFuturePlan) {
        return
      }

      this.selectedPlan = plan

      this.filteredPlans = this.$_.filter(this.$_.clone(this.plans), (o) => {
        return o.name !== this.tabProperties.clientPlan
      })

      this.checkShowUpgradeNotes()

      this.doodleForm.inProgress = true

      this.executeAPIWitoutValiation({
        url: '/api/compiler-api/paymentMethod',
        successAction: (data) => {
          let expires = moment(data.expires, 'MM/YYYY')
          if (expires.isBefore(new Date())) {
            this.showCCInSubscription = true
            this.loadPaymentDetails()
          } else {
            this.showCCInSubscription = false
          }
        },
        failureAction: (status) => {
          if (status === 402) {
            this.showCCInSubscription = true
            this.loadPaymentDetails()
          }
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.API,
        jdaEvent: 'payment-method',
        jdaLabel: ''
      })

      this.showPlanChangeSection = true
    },

    onApiPlanChange() {
      this.checkShowUpgradeNotes()
    },
    checkShowUpgradeNotes() {
      if (this.tabProperties.clientPlan) {
        let currentPlanIndex = window._.findIndex(this.plans, { name: this.tabProperties.clientPlan })
        let newPlanIndex = window._.findIndex(this.plans, { name: this.selectedPlan.name })

        if (newPlanIndex > currentPlanIndex) {
          this.showUpgradeNotes = true
        } else {
          this.showUpgradeNotes = false
        }
      }
    },
    loadPaymentDetails() {
      this.showUpdateCreditCard()
    },
    closeSubscriptionForm() {
      this.showPlanChangeSection = false
    },
    proceedWithSubscription(token) {
      this.executeAPIWitoutValiation({
        url: '/api/compiler-api/subscribe',
        data: { plan: this.selectedPlan.name, token: token },
        successAction: (data) => {
          eventBus.$emit('APIPlanChanged')
        },
        failureAction: () => {
          this.doodleForm.errorMessage += ' Unable to subscribe. Try again or contact JDoodle Support.'
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.API,
        jdaEvent: 'subscribe',
        jdaLabel: ''
      })
    },
    subscribePlan() {
      if (this.selectedPlan.name !== 'Free' && this.showCCInSubscription) {
        this.submitPaymentInfo()
      } else {
        this.proceedWithSubscription(null)
      }
    },
    submitPaymentInfo() {
      this.stripe.createToken(this.stripCard).then((result) => {
        if (result.error) {
          this.doodleForm.errorMessage = result.error.message
        } else {
          this.proceedWithSubscription(result.token.id)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../node_modules/bulma-pricingtable/dist/css/bulma-pricingtable.min.css";

.integrate_page {

  .pay_block {
    padding: 50px 0px;
    width: 50%;
    margin: 0 auto;

    .has-text-centered {
      margin-bottom: 15px;
    }

  }

  .integrate_container {
    .integrate_block {
      padding: 56px 0px 41px 8%;

      .integrate_block_info {
        display: flex;
        align-items: center;
        gap: 65px;
        margin-top: 62px;

        .integrate_left_block {
          display: flex;
          width: 39%;
          flex-direction: column;
          gap: 56px;
        }

        .integrate_right_block {
          width: 45%;

          img {
            width: 100%;
          }
        }
      }
    }

    .plugin_api {
      background: #f8f9f8;
      background-image: url("../../assets/images/integratePage/Integrate_bg.png");
      background-size: auto;
      background-position: center;
      padding: 130px 0px 110px 0px;
      display: flex;
      justify-content: center;
      gap: 160px;
    }

    .cards_block {
      padding: 105px 25px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      gap: 28px;

      .wide_card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f8f9f8;
        padding: 32px 59px;

        border-radius: 10px;

        >span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-align: center;

          color: #000000;
        }

        .per_day {
          text-align: center;

          div {
            display: flex;
            align-items: baseline;
            gap: 5px;

            background: #ffffff;
            border: 1px solid rgba(6, 24, 44, 0.3);
            border-radius: 5px;
            padding: 6px 50px;
            margin-bottom: 7px;

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 29px;
              letter-spacing: -0.02em;
              color: #000000;
            }

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 29px;
            }
          }

          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
          }
        }

        .one_credit {
          text-align: center;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;

          color: #000000;

          p {
            margin-bottom: 12px;
          }
        }

        .per_mounth {
          .start1 {
            display: none;
          }

          div {
            display: flex;
            align-items: center;
            gap: 14px;

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 39px;
              color: #000000;
            }

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #000000;
            }
          }
        }

        .wilde_card_button {
          text-align: center;

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 10px;

            color: #000000;
          }
        }
      }

      >p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        text-align: end;
      }

      .integrate_card_block {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;

        .integrate_card {
          text-align: center;
          background: #f8f9f8;
          padding: 20px 30px;
          border-radius: 10px;
          width: 254px;

          >span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;
          }

          .per_day {
            text-align: center;
            margin-top: 23px;

            div {
              background: #ffffff;
              border: 1px solid rgba(6, 24, 44, 0.3);
              border-radius: 5px;
              padding: 3px 35px;
              margin-bottom: 7px;
              display: flex;
              align-items: baseline;
              gap: 5px;

              p {
                font-family: "Inter";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.02em;
                color: #000000;
              }

              span {
                font-family: "Inter";
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 29px;
              }
            }

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
            }
          }

          .one_credit {
            text-align: center;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            margin-top: 77px;
            color: #000000;

            p {
              margin-bottom: 12px;
            }
          }

          .per_mounth {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 14px;
            margin-top: 60px;
            margin-bottom: 8px;

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 39px;
              color: #000000;
            }

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #000000;
            }
          }

          .integrate_card_button {
            text-align: center;

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 10px;

              color: #000000;
            }
          }
        }
      }
    }

  }

}

@media only screen and (max-width: 1200px) {
  .integrate_page {
    .integrate_container {

      .integrate_block {
        padding: 42px 0px 41px 6%;

        .integrate_block_info {
          justify-content: space-between;
          margin-top: 47px;

          .integrate_left_block {
            width: 48%;
            gap: 32px;
          }

          .integrate_right_block {
            width: 50%;
          }
        }
      }

      .plugin_api {
        padding: 95px 0px;
        gap: 85px;
      }

      .cards_block {
        padding: 105px 25px;
        gap: 30px;

        .wide_card {
          padding: 26px 20px;

          >span {
            font-size: 19px;
          }

          .per_day {
            div {
              padding: 5px 16px;
              margin-bottom: 5px;

              p {
                font-size: 20px;
              }

              span {
                font-size: 14px;
              }
            }

            span {
              font-size: 10px;
            }
          }

          .one_credit {
            font-size: 10px;

            p {
              margin-bottom: 7px;
            }
          }

          .per_mounth {
            div {
              gap: 9px;

              span {
                font-size: 25px;
                line-height: 34px;
              }

              p {
                font-size: 11px;
              }
            }
          }

          .wilde_card_button {
            p {
              font-size: 11px;
            }
          }
        }

        .integrate_card_block {
          justify-content: center;
        }
      }
    }
  }
}

.my_button {
  padding: 12px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #f5f5f5;
  background: #363739;
  border-radius: 30px;
  border: none;
}
@media only screen and (max-width: 1040px) {
  .my_button {
    padding: 9px 17px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .my_button {
    padding: 12px 20px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1040px) {
  .integrate_page {
    .integrate_container {
      .integrate_block {
        .integrate_block_info {
          margin-top: 35px;

          .integrate_left_block {
            gap: 20px;
          }

          .integrate_right_block {
            width: 44%;
          }
        }
      }

      .plugin_api {
        padding: 88px 0px;
        gap: 43px;
      }

      .cards_block {
        padding: 60px 45px;
        gap: 25px;
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .integrate_page {
    .integrate_container {
      .integrate_block {
        .integrate_block_info {
          flex-direction: column-reverse;

          .integrate_left_block {
            width: 75%;
          }

          .integrate_right_block {
            width: 75%;
          }
        }
      }

      .plugin_api {
        flex-direction: column;
        align-items: center;
        gap: 53px;
      }

      .cards_block {
        .wide_card {
          flex-direction: column;
          padding: 20px 30px;
          width: 254px;
          margin: auto;

          >span {
            font-size: 32px;
          }

          .per_day {
            div {
              margin-top: 23px;
              padding: 3px 25px;

              p {
                font-size: 24px;
              }

              span {
                font-size: 14px;
              }
            }

            span {
              font-size: 10px;
            }
          }

          .one_credit {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            margin-top: 77px;

            p {
              margin-bottom: 12px;
            }
          }

          .per_mounth {
            .start1 {
              display: block;
              text-align: center;
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
            }

            margin-top: 60px;

            div {
              .start2 {
                display: none;
              }

              gap: 14px;
              margin-bottom: 8px;

              span {
                font-size: 32px;
                line-height: 39px;
              }

              p {
                font-size: 14px;
                line-height: 17px;
              }
            }
          }

          .wilde_card_button {
            p {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 530px) {
  .integrate_page {
    background: #f8f9f8;

    .integrate_container {
      .pay_block {
        padding: 30px 0px;
        width: 80%;

      }

      .integrate_block {
        padding: 16px 24px 15px 24px;

        .integrate_block_info {
          .integrate_left_block {
            width: 90%;
          }

          .integrate_right_block {
            width: 90%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .integrate_page {
    .integrate_block {
      .integrate_block_info {
        margin-top: 27px;
        gap: 39px;

        .integrate_left_block {
          width: 100%;
          gap: 35px;
        }
      }
    }

    .plugin_api {
      background-image: unset;
      padding: 70px 24px;
    }

    .cards_block {
      background: #f8f9f8;
      padding: 0px 0px 70px 0px;

      .wide_card {
        background: #ffffff;
      }

      .integrate_card_block {
        .integrate_card {
          text-align: center;
          background: #ffffff;
          padding: 20px 30px;
          border-radius: 10px;
          width: 254px;

          >span {
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;
          }

          .per_day {
            text-align: center;
            margin-top: 23px;

            div {
              background: #ffffff;
              border: 1px solid rgba(6, 24, 44, 0.3);
              border-radius: 5px;
              padding: 3px 25px;
              margin-bottom: 7px;
              display: flex;
              align-items: baseline;
              gap: 3px;

              p {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.02em;
                color: #000000;
              }

              span {
                font-weight: 700;
                font-size: 15px;
                line-height: 29px;
              }
            }

            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
            }
          }

          .one_credit {
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            margin-top: 40px;
            color: #000000;

            p {
              margin-bottom: 12px;
            }
          }
        }

      }
    }
  }

}</style>
