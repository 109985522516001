import loadScriptInBody from './loadScriptInBody'

export default {
  data: function () {
    return {
      isCardAlreadyMounted: false,
      stripe: null
    }
  },
  computed: {
    poweredByStripeImage () {
      return require('../../assets/images/powered_by_stripe.png')
    }
  },
  methods: {
    showUpdateCreditCard () {
      if (this.isCardAlreadyMounted) {
        return
      }

      this.isCardAlreadyMounted = true
      this.doodleForm.completed = false

      let render = (count) => {
        if (count > 12) {
          this.doodleForm.errorMessage = 'Unable to update payment deatails. Please try again or contact JDoodle support.'
          return
        }

        if (!window.Stripe) {
          this.$_.delay(render, 1000, count + 1)
          return
        }
        // eslint-disable-next-line no-undef
        this.stripe = window.Stripe(this.tabProperties.stripeKey)
        let elements = this.stripe.elements()
        let style = {
          base: {
            // Add your base input styles here. For example:
            fontSize: '16px',
            color: '#32325d'
          }
        }

        this.stripCard = elements.create('card', { style: style })
        this.stripCard.mount('#apiUpdatePaymentMethod-card-element')

        this.doodleForm.errorMessage = ''
        this.isCardAlreadyMounted = false

        this.stripCard.addEventListener('change', (event) => {
          if (event.error) {
            this.doodleForm.errorMessage = event.error.message
          } else {
            this.doodleForm.errorMessage = null
          }
        })
      }

      this.doodleForm.errorMessage = 'loading payment fields, please wait....'

      loadScriptInBody.loadScriptInBody('https://js.stripe.com/v3/')
        .then(() => {
          this.$_.delay(render, 2000, 1)
        })
        .catch(() => {
        })
    }
  }
}
