<template>
  <div class="plugin_card">
    <div class="card_title">
      <p>{{ context.title }}</p>
    </div>
    <IntegratePagePluginItem :text="context.text1" />
    <IntegratePagePluginItem :text="context.text2" />
    <IntegratePagePluginItem :text="context.text3" />
  </div>
</template>
<script>
import IntegratePagePluginItem from './IntegratePagePluginItem.vue'
export default {
  name: 'integrate_page_plugin',
  components: {
    IntegratePagePluginItem
  },
  props: {
    context: {
      type: Object
    }
  }
}
</script>
<style lang="scss" scoped>
.plugin_card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 83px 20px 50px 20px;
  background: #ffffff;
  border-radius: 20px;
  width: 396px;
  gap: 40px;
  filter: drop-shadow(0px 10px 25px rgba(0, 0, 0, 0.15));
  .card_title {
    position: absolute;
    background: #fd5a0d;
    border-radius: 40px 40px 0px 40px;
    padding: 16px 40px;
    top: -35px;
    left: 0;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;

      color: #ffffff;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .plugin_card {
    padding: 76px 20px 40px 20px;
    width: 356px;
    .card_title {
      padding: 16px 40px;
      top: -35px;
      left: 0;
      p {
        font-size: 24px;
        line-height: 29px;

        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 1040px) {
  .plugin_card {
    padding: 66px 16px 30px 16px;
    width: 316px;
    .card_title {
      padding: 12px 32px;
      p {
        font-size: 20px;
      }
    }
  }
}
@media only screen and (max-width: 530px) {
  .plugin_card {
    padding: 38px 16px 30px 16px;
    width: 320px;
    height: 280px;
    gap: 8px;
    .card_title {
      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 155.6%;
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .plugin_card {
    padding: 52px 18px 40px 19px;
    width: 100%;
    .card_title {
      padding: 8px 20px;
      top: -22px;
    }
  }
}
</style>
