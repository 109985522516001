<template>
  <div class="home-container api">
    <div v-if="!tabProperties.clientPlan" class="snicher_banner">
      <h1>Do you want to <span>
          Turbocharge <img alt="" src="../assets/images/homePage/header.svg" />
        </span> the CS learning experience and efficiency with innovative tools?</h1>
      <a class="my_button" style="display: flex; width: fit-content;"
        href="https://app.monstercampaigns.com/c/eelbkirqhtobo1lclzwx/" target="_blank" rel="noopener noreferrer">Let’s us
        talk</a>
    </div>
    <DoodleMessage v-show="doodleForm.errorMessage" :message="doodleForm.errorMessage" class="has-text-grey-dark"
      message-class="has-text-danger" />
    <div v-if="tabProperties.clientPlan" class="tabs is-centered is-toggle is-rounded is-small api_tabs">
      <ul>
        <li v-for="tab in tabs" v-bind:key="tab.name" class="is-paddingless has-text-weight-bold "
          v-bind:class="{ 'is-active': currentTab === tab }">
          <a v-bind:class="{ 'has-text-dark-grey': currentTab !== tab }" @click="changeTab(tab)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <div>
      <component v-bind:is="currentTabComponent" v-if="!doodleForm.errorMessage"
        v-bind="{ tabProperties: tabProperties }">
      </component>
    </div>

  </div>
</template>

<script>
import CommentBox from '../components/Utils/CommentsBox'
import formMixin from '../assets/javascript/form-mixin'
import ApiPriceTable from '../components/API/APIPriceTable'
import { eventBus } from './../assets/javascript/event-bus'
import DoodleMessage from '../components/Utils/DoodleMessage'
import { JD_CONST } from '../assets/javascript/constants'
import loadScriptInBody from '@/assets/javascript/loadScriptInBody'

export default {
  name: 'home',
  mixins: [formMixin],
  components: { DoodleMessage, ApiPriceTable, CommentBox },
  data: function () {
    return {
      tabs: [{ name: 'Current Plan', component: () => import('../components/API/APICurrentPlan') },
      { name: 'Credits Used', component: () => import('../components/API/APICreditUsed') },
      { name: 'Change Plan', component: () => import('../components/API/APIPriceTable') },
      { name: 'Credentials', component: () => import('../components/API/APICredentials') },
      { name: 'Credit Card', component: () => import('../components/API/APICardDetails') },
      { name: 'Payments', component: () => import('../components/API/APIPayments') }
      ],
      currentTab: null,
      currentTabComponent: null,
      tabProperties: {
        isUserLoggedIn: false,
        stripe: {},
        stripCard: {},
        clientPlan: null,
        isUnlimited: false,
        clientFuturePlan: null,
        clientFuturePlanDetail: {},
        clientPlanDetail: {},
        isSubscriptionCancelled: false,
        hasPayAccount: false,
        stripeKey: '',
        plans: [
          { name: 'Free', value: 'Free', description: '200 Credits Per Day' },
          { name: '$10', value: '$10', description: '1,000 Credits Per Day' },
          { name: '$20', value: '$20', description: '2,000 Credits Per Day' },
          { name: '$50', value: '$50', description: '5,000 Credits Per Day' },
          { name: '$100', value: '$100', description: '10,000 Credits Per Day' },
          { name: '$200', value: '$200', description: '20,000 Credits Per Day' },
          { name: '$300', value: '$300', description: '30,000 Credits Per Day' },
          { name: '$400', value: '$400', description: '40,000 Credits Per Day' },
          { name: '$500', value: '$500', description: '50,000 Credits Per Day' },
          { name: '$1000', value: '$1000', description: '100,000 Credits Per Day' }
        ]
      }
    }
  },
  mounted() {
    eventBus.$on(JD_CONST.SIGN_IN, () => {
      this.isUserLoggedIn = true
      this.initPage()
    })

    eventBus.$on('APIPlanChanged', () => {
      this.initPage()
    })

    eventBus.$on('APIChangeTab', (tab) => {
      this.changeTab(tab)
    })

    this.currentTab = null
    this.currentTabComponent = () => import('../components/API/APIPriceTable')
    this.initPage()
  },
  beforeDestroy() {
    this.unloadSnitcher()
    eventBus.$off(JD_CONST.SIGN_IN)
    eventBus.$off('APIPlanChanged')
    eventBus.$off('APIChangeTab')
  },
  methods: {
    initPage() {
      this.executeAPIWitoutValiation({
        url: '/api/compiler-api/init',
        successAction: (data) => {
          if (data.plan) {
            this.tabProperties.clientPlan = data.plan
            this.tabProperties.isUnlimited = data.isUnlimited
            this.tabProperties.clientPlanDetail = window._.find(this.tabProperties.plans, { name: data.plan })
            this.tabProperties.isSubscriptionCancelled = data.isCancelled
            this.tabProperties.hasPayAccount = data.hasStripeAccount
            this.currentTab = this.tabs[0]
            this.currentTabComponent = this.currentTab.component
          } else {
            this.tabProperties.clientPlanDetail = {}
            this.tabProperties.clientPlan = null
            this.tabProperties.isUnlimited = false
            this.tabProperties.isSubscriptionCancelled = false
            this.tabProperties.hasPayAccount = false

            this.loadSnitcher()
          }

          if (data.futurePlan) {
            this.tabProperties.clientFuturePlan = data.futurePlan
            this.tabProperties.clientFuturePlanDetail = window._.find(this.tabProperties.plans, { name: data.futurePlan })
          } else {
            this.tabProperties.clientFuturePlan = null
            this.tabProperties.clientFuturePlanDetail = {}
          }

          if (data.isUnlimited) {
            this.tabs.splice(2, 1)
          }

          this.tabProperties.stripeKey = data.stripeKey
        },
        markCompleted: true,
        form: this.doodleForm
      })
    },
    changeTab(tab) {
      this.currentTab = tab
      this.currentTabComponent = tab.component
    },
    loadSnitcher() {
      if (process.env.NODE_ENV === 'development') {
        return
      }

      loadScriptInBody.loadScriptInBody('https://snid.snitcher.com/8422298.js')
        .then(() => {
          window.snid('verify', '8422298')
        })
        .catch(() => {
        })

      loadScriptInBody.loadScriptInBody('https://a.omappapi.com/app/js/api.min.js', [{ name: 'user', value: '269893' }, { name: 'account', value: '287518' }])
        .then(() => {
        })
        .catch(() => {
        })
    },
    unloadSnitcher() {
      if (process.env.NODE_ENV === 'development') {
        return
      }

      loadScriptInBody.unloadScriptInBody('https://snid.snitcher.com/8422298.js')
      loadScriptInBody.unloadScriptInBody('https://a.omappapi.com/app/js/api.min.js')
    }
  }
}
</script>

<style lang="scss" scoped>
.snicher_banner {
  background: #363739;
  padding: 8% 12% 8% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  span {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fd5a0d;
    margin: 0px 22px;
  }

  h1 {
    font-size: 2em;
    color: #fff;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5em;

    @media (max-width: 768px) {
      font-size: 2em;
    }
  }

  a {
    background: #fff;
    color: #363739;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-weight: 700;
    font-size: 1.2em;
    text-decoration: none;
    text-align: center;
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 768px) {
      font-size: 1em;
    }


    &:hover {
      font-weight: 800;
    }
  }
}

z-index .api {
  padding: 50px 0px;

  .api_tabs {
    justify-content: center;
  }

  .sub-title {
    font-size: 0.5em;
    font-style: italic;
    color: tomato;
    padding: 0.2em;
  }

  ul {
    flex-wrap: wrap;
    max-width: 95%;
  }

  li {
    padding: 0.3em;
  }

  .li-points {
    margin-left: 1em;
    font-size: 0.9em;
    font-weight: bold;
  }

  .tabs a {
    background-color: #f5f5f5;
    border-color: #b5b5b5;
  }

  article {
    @media screen and (max-width: 769px) {
      padding-left: 1px;
      padding-right: 1px;
    }
  }

  .doc-link {
    text-decoration: underline;
    margin-top: -15px;
    margin-bottom: 10px;
  }

}
</style>
