<template>
  <div class="integrate_page_item">
    <div class="integrate_page_item_title">
      <img src="../../../assets/images/integratePage/integrateIcon.png" />
      <p style="white-space: pre-wrap;">{{ integrateBlockItem.title }}</p>
    </div>
    <p>{{ integrateBlockItem.text }}</p>
  </div>
</template>
<script>
export default {
  name: 'integrate_page_item',
  props: {
    integrateBlockItem: {
      type: Object,
      require: true
    }
  }
}
</script>
<style lang="scss" scoped>
.integrate_page_item {
  > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 141%;
    letter-spacing: 0.01em;

    margin-top: 4px;
    color: #000000;
  }
  .integrate_page_item_title {
    display: flex;
    align-items: center;
    gap: 17px;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 141%;
      letter-spacing: 0.01em;

      color: #000000;
    }
  }
}
@media only screen and (max-width: 1040px) {
  .integrate_page_item {
    > p {
      font-weight: 400;
      font-size: 14px;
    }
    .integrate_page_item_title {
      display: flex;
      align-items: center;
      gap: 17px;
      p {
        font-size: 16px;
        line-height: 131%;

        color: #000000;
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .integrate_page_item {
    > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 141%;
    }
    .integrate_page_item_title {
      display: flex;
      align-items: center;
      gap: 17px;
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 141%;
      }
    }
  }
}
</style>
