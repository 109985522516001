<template>
  <div class="plugin_item">
    <img src="../../../assets/images/integratePage/pluginIcon.svg" />
    <p>{{ text }}</p>
  </div>
</template>
<script>
export default {
  name: 'Integrate_page_plugin_item',
  props: {
    text: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
.plugin_item {
  display: flex;
  align-items: center;
  gap: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  color: #000000;
}

@media only screen and (max-width: 1200px) {
  .plugin_item {
    gap: 30px;
    font-size: 20px;
    line-height: 31px;
    color: #000000;
  }
}
@media only screen and (max-width: 1040px) {
  .plugin_item {
    font-size: 16px;
    gap: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .plugin_item {
    font-weight: 500;
    font-size: 15px;
    line-height: 155.6%;
  }
}
</style>
